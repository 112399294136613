
import { Options } from "vue-class-component";
import NodesMinxins from "../NodesMixins";

@Options({
    name: "flowable-nodes-cc-node",
    emits: ["selected", "delNode", "insertNode"],
})
export default class CcNode extends NodesMinxins {
    private showError = false;
    private errorInfo = "";

    get content() {
        const config = this.config.props;
        if (config.shouldAdd) {
            return "由发起人指定";
        } else {
            let texts: any = [];
            const config = this.config.props;
            if (config.assignedOrg && config.assignedOrg.length > 0) config.assignedOrg.forEach((item: any) => texts.push(item.title));
            if (config.assignedDept && config.assignedDept.length > 0) config.assignedDept.forEach((item: any) => texts.push(item.title));
            if (config.assignedPost && config.assignedPost.length > 0) config.assignedPost.forEach((item: any) => texts.push(item.title));
            if (config.assignedUser && config.assignedUser.length > 0) config.assignedUser.forEach((item: any) => texts.push(item.title));
            if (texts.length > 0) {
                return String(texts).replaceAll(",", "、");
            } else {
                return "未指定";
            }
        }
    }

    //校验数据配置的合法性
    public validate(err: any) {
        this.showError = false;
        if (this.config.props.shouldAdd) {
            this.showError = false;
        } else if (
            this.config.props.assignedOrg.length === 0 &&
            this.config.props.assignedDept.length === 0 &&
            this.config.props.assignedPost.length === 0 &&
            this.config.props.assignedUser.length === 0
        ) {
            this.showError = true;
            this.errorInfo = "请选择需要抄送的人员或部门";
        }
        if (this.showError) {
            err.push(`抄送节点 ${this.config.name} 未设置抄送人`);
        }
        if (this.formItems.length !== this.config.props.formPerms.length) {
            this.errorInfo = "表单审批节点权限部分缺失：【请打开审批流程配置审批节点】";
            err.push("表单审批节点权限部分缺失：【请打开审批流程配置审批节点】");
            this.showError = true;
        }
        return !this.showError;
    }
}
